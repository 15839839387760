// 暫定対応
// import { createWaveMoniteringSetting, createDeviceMoniteringSetting, createDisplaySetting } from '../graphql/mutations';

import { API, graphqlOperation } from 'aws-amplify';
import { listWaveMoniteringSettings, listDeviceMoniteringSettings, listDisplaySettings } from '../graphql/queries';
import { updateWaveMoniteringSetting, updateDeviceMoniteringSetting, updateDisplaySetting } from '../graphql/mutations';
export const SETTING_ITEM_TYPE_INPUT = "input";
export const SETTING_ITEM_TYPE_SELECT = "select";
export const SETTING_ITEM_TYPE_SETTEI_ITEM_2 = "item2";

export const SETTING_INPUTBOX_UNIT_DBM_MHZ = "dBm/MHz";
export const SETTING_INPUTBOX_UNIT_KAI = "Kai";
export const SETTING_INPUTBOX_UNIT_SECOND = "Byo";
export const SETTING_INPUTBOX_UNIT_MINUTE = "Hun";
export const SETTING_INPUTBOX_UNIT_HOUR = "Jikan";
export const SETTING_INPUTBOX_UNIT_TEMPERTURE = "Do";
export const SETTING_INPUTBOX_UNIT_DENRYU = "A";
export const SETTING_INPUTBOX_UNIT_NOTHING = "Off";

// SetteiInputBoxのコンポーネントでは対応していない。SetteiItemWrapperで個別対応で表示可能にしている
export const SETTING_INPUTBOX_UNIT_DBM = "dBm";
export const SETTING_INPUTBOX_UNIT_DBI = "dBi";


export const getKeisokuSettingJson = async (location_mame) => {
    let keisokuSettingJson = null;
    try {
        const variables = {
            filter: {
                location_name: {
                    eq: location_mame
                }
            }
        };
        const result = await API.graphql(graphqlOperation(listWaveMoniteringSettings, variables));

        keisokuSettingJson = JSON.parse(result.data.listWaveMoniteringSettings.items[0].setting_json);
    } catch (error) {
        console.log("getKeisokuSettingJson", error);
    }

    return keisokuSettingJson;
}

export const updateKeisokuSettingJson = async (location_mame, keisokuSettingJson) => {
    try {
        // dynamodbからデータを取得
        const variables = {
            filter: {
                location_name: {
                    eq: location_mame
                }
            }
        };
        const resultListWaveMoniteringSetting = await API.graphql(graphqlOperation(listWaveMoniteringSettings, variables));

        if (resultListWaveMoniteringSetting.data.listWaveMoniteringSettings.items.length > 0) {
            const result = await API.graphql(graphqlOperation(updateWaveMoniteringSetting, {
                input: {
                    id: resultListWaveMoniteringSetting.data.listWaveMoniteringSettings.items[0].id,
                    setting_json: JSON.stringify(keisokuSettingJson),
                }
            }));

            console.log("updateKeisokuSettingJson result", result);
        }


    } catch (error) {
        console.log(error);
    }

    return;
}

export const getDeviceMoniteringSettingJson = async (location_mame) => {
    let deviceMoniteringSettingJson = null;
    try {
        const variables = {
            filter: {
                location_name: {
                    eq: location_mame
                }
            }
        };
        const result = await API.graphql(graphqlOperation(listDeviceMoniteringSettings, variables));

        deviceMoniteringSettingJson = JSON.parse(result.data.listDeviceMoniteringSettings.items[0].setting_json);
    } catch (error) {
        console.log("getDeviceMoniteringSettingJson", error);
    }

    return deviceMoniteringSettingJson;
}

export const updateDeviceMoniteringSettingJson = async (location_mame, deviceMoniteringSettingJson) => {
    try {
        const variables = {
            filter: {
                location_name: {
                    eq: location_mame
                }
            }
        };
        const resultListDeviceMoniteringSettings = await API.graphql(graphqlOperation(listDeviceMoniteringSettings, variables));

        if (resultListDeviceMoniteringSettings.data.listDeviceMoniteringSettings.items.length > 0) {
            const result = await API.graphql(graphqlOperation(updateDeviceMoniteringSetting, {
                input: {
                    id: resultListDeviceMoniteringSettings.data.listDeviceMoniteringSettings.items[0].id,
                    setting_json: JSON.stringify(deviceMoniteringSettingJson),
                }
            }));

            console.log("deviceMoniteringSettingJson result", result);
        }

    } catch (error) {
        console.log(error);
    }
}
// 
export const convertSettingValueToInputValue = (unit, value) => {
    // console.log("unit", unit);
    // console.log("value", value);
    switch (unit) {
        // 分と時間の設定値は秒で設定されているので、分と時間に変換する
        case SETTING_INPUTBOX_UNIT_MINUTE:
            return value / 60;
        case SETTING_INPUTBOX_UNIT_HOUR:
            return value / 3600;
        default:
            return value;
    }
}

// 
export const convertInputValueToSettingValue = (unit, value) => {
    // console.log("unit", unit);
    // console.log("value", value);
    switch (unit) {
        // 分と時間の設定値は秒で設定されているので、分と時間に変換する
        case SETTING_INPUTBOX_UNIT_MINUTE:
            return value * 60;
        case SETTING_INPUTBOX_UNIT_HOUR:
            return value * 3600;
        default:
            return value;
    }
}

export const convertInputValueToSelctValue = (label, value) => {
    switch (label) {
        case "RBW":
        case "VBW":
            switch (value) {
                case 10:
                    return "10kHz";
                case 30:
                    return "30kHz";
                case 100:
                    return "100kHz";
                case 300:
                    return "300kHz";
                case 1000:
                    return "1MHz";
                default:
                    return value;
            }
        default:
            return value;
    }
}

export const convertSelectValueToInputValue = (label, value) => {
    switch (label) {
        case "RBW":
        case "VBW":
            switch (value) {
                case "10kHz":
                    return 10;
                case "30kHz":
                    return 30;
                case "100kHz":
                    return 100;
                case "300kHz":
                    return 300;
                case "1MHz":
                    return 1000;
                default:
                    return value;
            }
        case "Spectrum_Analyzer_1":
        case "Spectrum_Analyzer_2":
            switch (value) {
                case "アンテナ1":
                    return 1;
                case "アンテナ2":
                    return 2;
                case "アンテナ3":
                    return 3;
                default:
                    return null;
            }

        default:
            return value;
    }
}

export const convertStatusTypeValueToSelectValue = (value) => {
    switch (value) {
        case "Error":
            return "異常";
        case "Warning":
            return "警告";
        case "Info":
            return "情報";
        default:
            return value;
    }
}

export const convertSelectValueToStatusTypeValue = (value) => {
    switch (value) {
        case "異常":
            return "Error";
        case "警告":
            return "Warning";
        case "情報":
            return "Info";
        default:
            return "Error";
    }
}

export const getDisplaySettingJson = async () => {
    let displaySettingJson = null;
    try {
        const result = await API.graphql(graphqlOperation(listDisplaySettings));
        displaySettingJson = JSON.parse(result.data.listDisplaySettings.items[0].setting_json);
    } catch (error) {
        console.log("getDisplaySettingJson", error);
    }
    // console.log("getDisplaySettingJson", displaySettingJson);
    return displaySettingJson;
}

export const updateDisplaySettingJson = async (displaySettingJson) => {
    try {
        const resultListDisplaySettings = await API.graphql(graphqlOperation(listDisplaySettings));

        if (resultListDisplaySettings.data.listDisplaySettings.items.length > 0) {
            const result = await API.graphql(graphqlOperation(updateDisplaySetting, {
                input: {
                    id: resultListDisplaySettings.data.listDisplaySettings.items[0].id,
                    setting_json: JSON.stringify(displaySettingJson),
                }
            }));

            console.log("deviceMoniteringSettingJson result", result);
        }

    } catch (error) {
        console.log(error);
    }
}

export function mergeObjects(obj1, obj2) {
    // obj1をコピーして新しいオブジェクトを作成
    const result = obj1 !== null ? { ...obj1 } : null;

    if (result === null) {
        return obj2;
    }

    for (const key in obj2) {
        if (obj2.hasOwnProperty(key)) {
            if (typeof obj2[key] === 'object' && !Array.isArray(obj2[key])) {
                // サブオブジェクトの場合、再帰的にマージ
                if (result.hasOwnProperty(key) && typeof result[key] === 'object' && !Array.isArray(result[key])) {
                    result[key] = mergeObjects(result[key], obj2[key]);
                }
            } else {
                // キーが存在する場合のみ追加
                if (result.hasOwnProperty(key)) {
                    result[key] = obj2[key];
                }
            }
        }
    }

    return result;
}

// 入力数字を制限する関数
export const inputNumberLimit = (value, step, range = null) => {
    // 整数の場合
    if (step === 1) {
        // 整数に変換
        value = !isNaN(parseInt(value)) ? parseInt(value) : 0;
    }   
    else {
        // 小数に変換
        value = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;

        // stepから小数点以下の桁数を取得
        const stepStr = step.toString();
        const decimalPointIndex = stepStr.indexOf(".");
        const decimalPointLength = decimalPointIndex !== -1 ? stepStr.length - decimalPointIndex - 1 : 0;

        // 小数点以下の桁数を制限
        value = parseFloat(value.toFixed(decimalPointLength));
    }

    // 最小値より小さい場合は、最小値にする
    if (range !== null && value < range.min) {
        value = range.min;
    }
    // 最大値より大きい場合は、最大値にする
    if (range !== null && value > range.max) {
        value = range.max;
    }

    return value;
}

export const keisokuSettingJson = {
    "Data Output Interval": {
        labeltext: "データ統合間隔",
        type: SETTING_ITEM_TYPE_INPUT,
        unit: SETTING_INPUTBOX_UNIT_MINUTE,
        input: {
            type: "number",
            step: 1,
        },
        range: {
            min: 1,
            max: 60,
        },
        // inputvalue:10,
    },
    "Calibration Interval": {
        labeltext: "校正処理間隔",
        type: SETTING_ITEM_TYPE_INPUT,
        unit: SETTING_INPUTBOX_UNIT_HOUR,
        input: {
            type: "number",
            step: 1,
        },
        range: {
            min: 1,
            max: 24,
        },
        // inputvalue:1,
    },
    "RBW": {
        labeltext: "RBW",
        type: SETTING_ITEM_TYPE_SELECT,
        options: [
            "10kHz",
            "30kHz",
            "100kHz",
            "300kHz",
            "1MHz"
        ],
        // inputvalue:"300kHz",
    },
    "VBW": {
        labeltext: "VBW",
        type: SETTING_ITEM_TYPE_SELECT,
        options: [
            "10kHz",
            "30kHz",
            "100kHz",
            "300kHz",
            "1MHz"
        ],
        // inputvalue:"10kHz",
    },
    "monAntGain": {
        labeltext: "モニタリングアンテナ利得",
        type: SETTING_ITEM_TYPE_INPUT,
        unit: SETTING_INPUTBOX_UNIT_DBI,
        range: null,
        input: {
            type: "number",
            step: 0.1,
        },
        // inputvalue:10,
    },
    "earthAntGain": {
        labeltext: "地球局軸外アンテナ利得",
        type: SETTING_ITEM_TYPE_INPUT,
        unit: SETTING_INPUTBOX_UNIT_DBI,
        range: null,
        input: {
            type: "number",
            step: 0.1,
        },
        // inputvalue:10,
    },
}

export const keisokuCycleSettingJson = {
    "Spectrum_Analyzer_1": {
        labeltext: "スペアナ1計測サイクル",
        // "1st" : 1,
        // "2nd" : 2,
        // "3rd" : 3
    },
    "Spectrum_Analyzer_2": {
        labeltext: "スペアナ2計測サイクル",
        // "1st" : 2,
        // "2nd" : 3,
        // "3rd" : 1
    },
}

export const keisokuKanshiSettingJson = {
    "3400-3440": {
        "Threshold A": {
            labeltext: "A：時間率判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"OFF",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: "",
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold B": {
            labeltext: "B：最大値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"OFF",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: "",
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold C": {
            labeltext: "C：合計値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: -21.990,
            },
            // option2:{},
            // option3:{},
            // option4:{},
        },
    },

    "3440-3480": {
        "Threshold A": {
            labeltext: "A：時間率判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"OFF",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: "",
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold B": {
            labeltext: "B：最大値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"OFF",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: "",
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold C": {
            labeltext: "C：合計値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: -21.990,
            },
            // option2:{},
            // option3:{},
            // option4:{},
        },
    },

    "3480-3520": {
        "Threshold A": {
            labeltext: "A：時間率判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"OFF",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: "",
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold B": {
            labeltext: "B：最大値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"OFF",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: "",
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold C": {
            labeltext: "C：合計値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: -21.990,
            },
            // option2:{},
            // option3:{},
            // option4:{},
        },
    },

    "3520-3560": {
        "Threshold A": {
            labeltext: "A：時間率判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"OFF",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: "",
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold B": {
            labeltext: "B：最大値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"OFF",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: "",
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold C": {
            labeltext: "C：合計値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: -21.990,
            },
            // option2:{},
            // option3:{},
            // option4:{},
        },
    },

    "3560-3600": {
        "Threshold A": {
            labeltext: "A：時間率判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"OFF",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: "",
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold B": {
            labeltext: "B：最大値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"OFF",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: "",
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold C": {
            labeltext: "C：合計値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: -21.990,
            },
            // option2:{},
            // option3:{},
            // option4:{},
        },
    },

    "3600-3700": {
        "Threshold A": {
            labeltext: "A：時間率判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: -118.649,
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold B": {
            labeltext: "B：最大値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: -106.949,
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold C": {
            labeltext: "C：合計値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"OFF",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: "",
            },
            // option2:{},
            // option3:{},
            // option4:{},
        },
    },

    "3700-3800": {
        "Threshold A": {
            labeltext: "A：時間率判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: -118.649,
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold B": {
            labeltext: "B：最大値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: -106.949,
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold C": {
            labeltext: "C：合計値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"OFF",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: "",
            },
            // option2:{},
            // option3:{},
            // option4:{},
        },
    },

    "3800-3900": {
        "Threshold A": {
            labeltext: "A：時間率判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"OFF",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: "",
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold B": {
            labeltext: "B：最大値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"OFF",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: "",
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold C": {
            labeltext: "C：合計値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: -21.990,
            },
            // option2:{},
            // option3:{},
            // option4:{},
        },
    },

    "3900-4000": {
        "Threshold A": {
            labeltext: "A：時間率判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: -118.649,
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold B": {
            labeltext: "B：最大値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: -106.949,
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold C": {
            labeltext: "C：合計値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"OFF",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: "",
            },
            // option2:{},
            // option3:{},
            // option4:{},
        },
    },

    "4000-4100": {
        "Threshold A": {
            labeltext: "A：時間率判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: -118.649,
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold B": {
            labeltext: "B：最大値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: -106.949,
            },
            // option2:null,
            // option3:null,
            // option4:null,
        },
        "Threshold C": {
            labeltext: "C：合計値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"OFF",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: "",
            },
            // option2:{},
            // option3:{},
            // option4:{},
        },
    },

    "total": {
        "Threshold D": {
            labeltext: "D：合計値判定",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                labeltext: "",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DBM,
                input: {
                    type: "number",
                    step: 0.01,
                },
                range: {
                    min: -200,
                    max: 0,
                },
                // inputvalue: -80.000,
            },
            // option2:{},
            // option3:{},
            // option4:{},
        },
    }

}

export const signalKanshiSettingJson = {
    "S1": {
        "A1": {
            "signal_threshold": {
                labeltext: "アンテナ１",
                isvalidoptions: ["ON", "OFF"],
                // isvalidvalue:"OFF",
                statustypeoptions: ["異常", "警告", "情報"],
                // statustypevalue:"異常",
                option1: {
                    labeltext: "",
                    type: SETTING_ITEM_TYPE_INPUT,
                    unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    input: {
                        type: "number",
                        step: 0.01,
                    },
                    range: {
                        min: 0,
                        max: 15,
                    },
                },
            },
        },
        "A2": {
            "signal_threshold": {
                labeltext: "アンテナ２",
                isvalidoptions: ["ON", "OFF"],
                // isvalidvalue:"OFF",
                statustypeoptions: ["異常", "警告", "情報"],
                // statustypevalue:"異常",
                option1: {
                    labeltext: "",
                    type: SETTING_ITEM_TYPE_INPUT,
                    unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    input: {
                        type: "number",
                        step: 0.01,
                    },
                    range: {
                        min: 0,
                        max: 15,
                    },
                },
            },
        },
        "A3": {
            "signal_threshold": {
                labeltext: "アンテナ３",
                isvalidoptions: ["ON", "OFF"],
                // isvalidvalue:"OFF",
                statustypeoptions: ["異常", "警告", "情報"],
                // statustypevalue:"異常",
                option1: {
                    labeltext: "",
                    type: SETTING_ITEM_TYPE_INPUT,
                    unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    input: {
                        type: "number",
                        step: 0.01,
                    },
                    range: {
                        min: 0,
                        max: 15,
                    },
                },
            },
        },
    },
    "S2": {
        "A1": {
            "signal_threshold": {
                labeltext: "アンテナ１",
                isvalidoptions: ["ON", "OFF"],
                // isvalidvalue:"OFF",
                statustypeoptions: ["異常", "警告", "情報"],
                // statustypevalue:"異常",
                option1: {
                    labeltext: "",
                    type: SETTING_ITEM_TYPE_INPUT,
                    unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    input: {
                        type: "number",
                        step: 0.01,
                    },
                    range: {
                        min: 0,
                        max: 15,
                    },
                },
            },
        },
        "A2": {
            "signal_threshold": {
                labeltext: "アンテナ２",
                isvalidoptions: ["ON", "OFF"],
                // isvalidvalue:"OFF",
                statustypeoptions: ["異常", "警告", "情報"],
                // statustypevalue:"異常",
                option1: {
                    labeltext: "",
                    type: SETTING_ITEM_TYPE_INPUT,
                    unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    input: {
                        type: "number",
                        step: 0.01,
                    },
                    range: {
                        min: 0,
                        max: 15,
                    },
                },
            },
        },
        "A3": {
            "signal_threshold": {
                labeltext: "アンテナ３",
                isvalidoptions: ["ON", "OFF"],
                // isvalidvalue:"OFF",
                statustypeoptions: ["異常", "警告", "情報"],
                // statustypevalue:"異常",
                option1: {
                    labeltext: "",
                    type: SETTING_ITEM_TYPE_INPUT,
                    unit: SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    input: {
                        type: "number",
                        step: 0.01,
                    },
                    range: {
                        min: 0,
                        max: 15,
                    },
                },
            },
        },
    }
}

export const machineKanshiSettingJson = {
    "Spectrum_Analyzer_1": {
        "Ping": {
            labeltext: "PING監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 300,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Non_Response_Count",
                labeltext: "無応答判定回数",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_KAI,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 3,
                    max: 10,
                },
                // inputvalue: 5,
            },
            // option3:null,
            // option4:null,
        },
    },
    "Spectrum_Analyzer_2": {
        "Ping": {
            labeltext: "PING監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 300,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Non_Response_Count",
                labeltext: "無応答判定回数",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_KAI,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 3,
                    max: 10,
                },
                // inputvalue: 5,
            },
            // option3:null,
            // option4:null,
        },
    },
    "Gps_Sensor_1": {
        "Value": {
            labeltext: "死活監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 300,
                },
                // inputvalue: 180,
            }
        },
    },
    "Gps_Sensor_2": {
        "Value": {
            labeltext: "死活監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 300,
                },
                // inputvalue: 180,
            }
        },
    },
    "Direct_Current_Power_1": {
        "Ping": {
            labeltext: "PING監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 300,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Non_Response_Count",
                labeltext: "無応答判定回数",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_KAI,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 3,
                    max: 10,
                },
                // inputvalue: 5,
            },
            // option3:null,
            // option4:null,
        },
        "Value": {
            labeltext: "消費電流監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"警告",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 180,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Max_Electricity",
                labeltext: "消費電流上限値",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DENRYU,
                input: {
                    type: "number",
                    step: 0.1,
                },
                range: {
                    min: 0.0,
                    max: 6.0,
                },
                // inputvalue: 5.0,
            },
            option3: {
                settingValueKey: "Min_Electricity",
                labeltext: "消費電流下限値",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DENRYU,
                input: {
                    type: "number",
                    step: 0.1,
                },
                range: {
                    min: 0.0,
                    max: 6.0,
                },
                // inputvalue: 1.0,
            },
        },
    },
    "Direct_Current_Power_2": {
        "Ping": {
            labeltext: "PING監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 300,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Non_Response_Count",
                labeltext: "無応答判定回数",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_KAI,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 3,
                    max: 10,
                },
                // inputvalue: 5,
            },
            // option3:null,
            // option4:null,
        },
        "Value": {
            labeltext: "消費電流監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"警告",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 180,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Max_Electricity",
                labeltext: "消費電流上限値",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DENRYU,
                input: {
                    type: "number",
                    step: 0.1,
                },
                range: {
                    min: 0.0,
                    max: 6.0,
                },
                // inputvalue: 5.0,
            },
            option3: {
                settingValueKey: "Min_Electricity",
                labeltext: "消費電流下限値",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DENRYU,
                input: {
                    type: "number",
                    step: 0.1,
                },
                range: {
                    min: 0.0,
                    max: 6.0,
                },
                // inputvalue: 1.0,
            },
        },
    },
    "Direct_Current_Power_3": {
        "Ping": {
            labeltext: "PING監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 300,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Non_Response_Count",
                labeltext: "無応答判定回数",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_KAI,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 3,
                    max: 10,
                },
                // inputvalue: 5,
            },
            // option3:null,
            // option4:null,
        },
        "Value": {
            labeltext: "消費電流監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"警告",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 180,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Max_Electricity",
                labeltext: "消費電流上限値",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DENRYU,
                input: {
                    type: "number",
                    step: 0.1,
                },
                range: {
                    min: 0.0,
                    max: 6.0,
                },
                // inputvalue: 5.0,
            },
            option3: {
                settingValueKey: "Min_Electricity",
                labeltext: "消費電流下限値",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DENRYU,
                input: {
                    type: "number",
                    step: 0.1,
                },
                range: {
                    min: 0.0,
                    max: 6.0,
                },
                // inputvalue: 1.0,
            },
        },
    },
    "Direct_Current_Power_4": {
        "Ping": {
            labeltext: "PING監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 300,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Non_Response_Count",
                labeltext: "無応答判定回数",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_KAI,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 3,
                    max: 10,
                },
                // inputvalue: 5,
            },
            // option3:null,
            // option4:null,
        },
        "Value": {
            labeltext: "消費電流監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"警告",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 0.1,
                },
                range: {
                    min: 10,
                    max: 180,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Max_Electricity",
                labeltext: "消費電流上限値",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DENRYU,
                input: {
                    type: "number",
                    step: 0.1,
                },
                range: {
                    min: 0.0,
                    max: 6.0,
                },
                // inputvalue: 5.0,
            },
            option3: {
                settingValueKey: "Min_Electricity",
                labeltext: "消費電流下限値",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DENRYU,
                input: {
                    type: "number",
                    step: 0.1,
                },
                range: {
                    min: 0.0,
                    max: 6.0,
                },
                // inputvalue: 1.0,
            },
        },
    },
    "Direct_Current_Power_5": {
        "Ping": {
            labeltext: "PING監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 300,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Non_Response_Count",
                labeltext: "無応答判定回数",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_KAI,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 3,
                    max: 10,
                },
                // inputvalue: 5,
            },
            // option3:null,
            // option4:null,
        },
        "Value": {
            labeltext: "消費電流監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"警告",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 180,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Max_Electricity",
                labeltext: "消費電流上限値",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DENRYU,
                input: {
                    type: "number",
                    step: 0.1,
                },
                range: {
                    min: 0.0,
                    max: 6.0,
                },
                // inputvalue: 5.0,
            },
            option3: {
                settingValueKey: "Min_Electricity",
                labeltext: "消費電流下限値",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DENRYU,
                input: {
                    type: "number",
                    step: 0.1,
                },
                range: {
                    min: 0.0,
                    max: 6.0,
                },
                // inputvalue: 1.0,
            },
        },
    },
    "Lan_Switch_1": {
        "Ping": {
            labeltext: "PING監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 300,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Non_Response_Count",
                labeltext: "無応答判定回数",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_KAI,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 3,
                    max: 10,
                },
                //.inputvalue: 5,
            },
            // option3:null,
            // option4:null,
        },
        "SNMP": {
            labeltext: "SNMPTrap",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["情報"],
            // statustypevalue:"情報",
        },
    },
    "Lan_Switch_2": {
        "Ping": {
            labeltext: "PING監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 300,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Non_Response_Count",
                labeltext: "無応答判定回数",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_KAI,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 3,
                    max: 10,
                },
                //.inputvalue: 5,
            },
            // option3:null,
            // option4:null,
        },
        "SNMP": {
            labeltext: "SNMPTrap",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["情報"],
            // statustypevalue:"情報",
        },
    },
    "Lan_Switch_3": {
        "Ping": {
            labeltext: "PING監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 300,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Non_Response_Count",
                labeltext: "無応答判定回数",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_KAI,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 3,
                    max: 10,
                },
                //.inputvalue: 5,
            },
            // option3:null,
            // option4:null,
        },
        "SNMP": {
            labeltext: "SNMPTrap",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["情報"],
            // statustypevalue:"情報",
        },
    },
    "Lan_Switch_4": {
        "Ping": {
            labeltext: "PING監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 300,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Non_Response_Count",
                labeltext: "無応答判定回数",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_KAI,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 3,
                    max: 10,
                },
                //.inputvalue: 5,
            },
            // option3:null,
            // option4:null,
        },
        "SNMP": {
            labeltext: "SNMPTrap",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["情報"],
            // statustypevalue:"情報",
        },
    },
    "Temperature_Sensor": {
        "Ping": {
            labeltext: "PING監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 300,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Non_Response_Count",
                labeltext: "無応答判定回数",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_KAI,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 3,
                    max: 10,
                },
                //.inputvalue: 5,
            },
            // option3:null,
            // option4:null,
        },
    },
    "Noise_Source": {
        "Value": {
            labeltext: "温度監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"警告",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 3600,
                },
                // inputvalue: 60,
            },
            option2: {
                settingValueKey: "Max_Temperature",
                labeltext: "温度上限値",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_TEMPERTURE,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 0,
                    max: 100,
                },
                // inputvalue: 80,
            },
            option3: {
                settingValueKey: "Min_Temperature",
                labeltext: "温度下限値",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_TEMPERTURE,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 0,
                    max: 100,
                },
                // inputvalue: 0,
            },
        },
    },
    "Outdoor_Cabinet": {
        "Value": {
            labeltext: "温度監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"警告",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 1,
                    max: 300,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Max_Temperature",
                labeltext: "温度上限値",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_TEMPERTURE,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 0,
                    max: 100,
                },
                // inputvalue: 50,
            },
            option3: {
                settingValueKey: "Min_Temperature",
                labeltext: "温度下限値",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_TEMPERTURE,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 0,
                    max: 100,
                },
                // inputvalue: 0,
            },
        },
    },
    "Energy_Gazer": {
        "Ping": {
            labeltext: "PING監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 300,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Non_Response_Count",
                labeltext: "無応答判定回数",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_KAI,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 3,
                    max: 10,
                },
                //.inputvalue: 5,
            },
            // option3:null,
            // option4:null,
        },
        "Value": {
            labeltext: "消費電流監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"警告",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_SECOND,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 10,
                    max: 180,
                },
                // inputvalue: 180,
            },
            option2: {
                settingValueKey: "Max_Electricity",
                labeltext: "消費電流上限値",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DENRYU,
                input: {
                    type: "number",
                    step: 0.1,
                },
                range: {
                    min: 0.0,
                    max: 6.0,
                },
                // inputvalue: 5.0,
            },
            option3: {
                settingValueKey: "Min_Electricity",
                labeltext: "消費電流下限値",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_DENRYU,
                input: {
                    type: "number",
                    step: 0.1,
                },
                range: {
                    min: 0.0,
                    max: 6.0,
                },
                // inputvalue: 1.0,
            },
        },
    },
    "Measurement_Pc_Main": {
        "Value": {
            labeltext: "死活監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_MINUTE,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 1,
                    max: 5,
                },
                // inputvalue: 60,
            }
        },
    },
    "Measurement_Pc_Sub": {
        "Value": {
            labeltext: "死活監視",
            isvalidoptions: ["ON", "OFF"],
            // isvalidvalue:"ON",
            statustypeoptions: ["異常", "警告", "情報"],
            // statustypevalue:"異常",
            option1: {
                settingValueKey: "Monitoring_Interval",
                labeltext: "監視周期",
                type: SETTING_ITEM_TYPE_INPUT,
                unit: SETTING_INPUTBOX_UNIT_MINUTE,
                input: {
                    type: "number",
                    step: 1,
                },
                range: {
                    min: 1,
                    max: 5,
                },
                // inputvalue: 60,
            }
        },
    },
};

// export const createInitialDisplaySetting = async () => {

//     const displaySettingJson = {
//         location_name: {
//             "YSCC" : "YSCC",
//             "SPE" : "SPE",
//             "SPW" : "SPW",
//             "SAYADO" : "小夜戸",
//         },
//         band_label: {
//             "3400-3440" : {
//                 "name"  : "S-1",
//                 "color" : "#bbbcbe"
//             },
//             "3440-3480" : {
//                 "name"  : "D-1",
//                 "color" : "#cc0033"
//             },
//             "3480-3520" : {
//                 "name"  : "D-2",
//                 "color" : "#cc0033"
//             },
//             "3520-3560" : {
//                 "name"  : "K-1",
//                 "color" : "#eb5505"
//             },
//             "3560-3600" : {
//                 "name"  : "S-2",
//                 "color" : "#bbbcbe"
//             },
//             "3600-3700" : {
//                 "name"  : "D-3",
//                 "color" : "#cc0033"
//             },
//             "3700-3800" : {
//                 "name"  : "K-2",
//                 "color" : "#eb5505"
//             },
//             "3800-3900" : {
//                 "name"  : "R-1",
//                 "color" : "#ff00ff"
//             },
//             "3900-4000" : {
//                 "name"  : "S-3",
//                 "color" : "#bbbcbe"
//             },
//             "4000-4100" : {
//                 "name"  : "K-3",
//                 "color" : "#eb5505"
//             }
//         }
//     }

//     const result = await API.graphql(graphqlOperation(createDisplaySetting, {input: {setting_json: JSON.stringify(displaySettingJson)}}));
//     console.log("result", result.data.createDisplaySetting);
// }

// export const createInitialWaveMoniterintSetting = async (location_name) => {

//     const keisokuSettingJsonData = {
//         "monAntGain": 17,
//         "earthAntGain": -10,
//         "RBW": 300,
//         "VBW": 10,
//         "Data Output Interval": 600,
//         "Calibration Interval": 3600,
//         "Spectrum_Analyzer_1": {
//             "1st": 1,
//             "2nd": 2,
//             "3rd": 3
//         },
//         "Spectrum_Analyzer_2": {
//             "1st": 2,
//             "2nd": 3,
//             "3rd": 1
//         },
//         "3400-3440": {
//             "Threshold A": {
//                 "Monitoring": false,
//                 "Status_Type": "Error",
//                 "Value": null
//             },
//             "Threshold B": {
//                 "Monitoring": false,
//                 "Status_Type": "Error",
//                 "Value": null
//             },
//             "Threshold C": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Value": -21.990
//             }
//         },
//         "3440-3480": {
//             "Threshold A": {
//                 "Monitoring": false,
//                 "Status_Type": "Error",
//                 "Value": null
//             },
//             "Threshold B": {
//                 "Monitoring": false,
//                 "Status_Type": "Error",
//                 "Value": null
//             },
//             "Threshold C": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Value": -21.990
//             }
//         },
//         "3480-3520": {
//             "Threshold A": {
//                 "Monitoring": false,
//                 "Status_Type": "Error",
//                 "Value": null
//             },
//             "Threshold B": {
//                 "Monitoring": false,
//                 "Status_Type": "Error",
//                 "Value": null
//             },
//             "Threshold C": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Value": -21.990
//             }
//         },
//         "3520-3560": {
//             "Threshold A": {
//                 "Monitoring": false,
//                 "Status_Type": "Error",
//                 "Value": null
//             },
//             "Threshold B": {
//                 "Monitoring": false,
//                 "Status_Type": "Error",
//                 "Value": null
//             },
//             "Threshold C": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Value": -21.990
//             }
//         },
//         "3560-3600": {
//             "Threshold A": {
//                 "Monitoring": false,
//                 "Status_Type": "Error",
//                 "Value": null
//             },
//             "Threshold B": {
//                 "Monitoring": false,
//                 "Status_Type": "Error",
//                 "Value": null
//             },
//             "Threshold C": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Value": -21.990
//             }
//         },
//         "3600-3700": {
//             "Threshold A": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Value":-118.649
//             },
//             "Threshold B": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Value": -106.949
//             },
//             "Threshold C": {
//                 "Monitoring": false,
//                 "Status_Type": "Error",
//                 "Value": null
//             }
//         },
//         "3700-3800": {
//             "Threshold A": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Value":-118.649
//             },
//             "Threshold B": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Value": -106.949
//             },
//             "Threshold C": {
//                 "Monitoring": false,
//                 "Status_Type": "Error",
//                 "Value": null
//             }
//         },
//         "3800-3900": {
//             "Threshold A": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Value":-118.649
//             },
//             "Threshold B": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Value": -106.949
//             },
//             "Threshold C": {
//                 "Monitoring": false,
//                 "Status_Type": "Error",
//                 "Value": null
//             }
//         },
//         "3900-4000": {
//             "Threshold A": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Value":-118.649
//             },
//             "Threshold B": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Value": -106.949
//             },
//             "Threshold C": {
//                 "Monitoring": false,
//                 "Status_Type": "Error",
//                 "Value": null
//             }
//         },
//         "4000-4100": {
//             "Threshold A": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Value":-118.649
//             },
//             "Threshold B": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Value": -106.949
//             },
//             "Threshold C": {
//                 "Monitoring": false,
//                 "Status_Type": "Error",
//                 "Value": null
//             }
//         },
//         "total": {
//             "Threshold D": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Value": -80,
//                 "Target": {
//                     "3400-3440": true,
//                     "3440-3480": true,
//                     "3480-3520": true,
//                     "3520-3560": true,
//                     "3560-3600": true,
//                     "3600-3700": true,
//                     "3700-3800": true,
//                     "3800-3900": true,
//                     "3900-4000": true,
//                     "4000-4100": true
//                 }
//             }
//         }
//     }

//     // if (location_name === "SPE") {
//     //     keisokuSettingJsonData["Calibration Interval"] = 7;
//     // } else if (location_name === "SPW") {
//     //     keisokuSettingJsonData["Calibration Interval"] = 3;
//     // } else if (location_name === "SAYADO") {
//     //     keisokuSettingJsonData["Calibration Interval"] = 4;
//     // }

//     const result = await API.graphql(graphqlOperation(createWaveMoniteringSetting, {input: {location_name: location_name, setting_json: JSON.stringify(keisokuSettingJsonData)}}));
//     console.log("keisokuSetting", result.data.createWaveMoniteringSetting);
// }

// export const createInitialDeviceMoniteringSetting = async (location_name) => {

//     const deviceMoniteringSettingJsonData = {
//         "Spectrum_Analyzer_1": {
//             "Ping": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Monitoring_Interval": 180,
//                 "Non_Response_Count": 5
//             }
//         },
//         "Spectrum_Analyzer_2": {
//             "Ping": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Monitoring_Interval": 180,
//                 "Non_Response_Count": 5
//             }
//         },
//         "Gps_Sensor_1": {
//             "Value": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Monitoring_Interval": 300,
//                 "Non_Response_Count": 5
//             }
//         },
//         "Gps_Sensor_2": {
//             "Value": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Monitoring_Interval": 300,
//                 "Non_Response_Count": 5
//             }
//         },
//         "Temperature_Sensor": {
//             "Ping": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Monitoring_Interval": 180,
//                 "Non_Response_Count": 5
//             }
//         },
//         "Direct_Current_Power_1": {
//             "Ping": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Monitoring_Interval": 180,
//                 "Non_Response_Count": 5
//             },
//             "Value": {
//                 "Monitoring": true,
//                 "Status_Type": "Warning",
//                 "Monitoring_Interval": 180,
//                 "Max_Electricity": 5.0,
//                 "Min_Electricity": 0.0
//             }
//         },
//         "Direct_Current_Power_2": {
//             "Ping": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Monitoring_Interval": 180,
//                 "Non_Response_Count": 5
//             },
//             "Value": {
//                 "Monitoring": true,
//                 "Status_Type": "Warning",
//                 "Monitoring_Interval": 180,
//                 "Max_Electricity": 5.0,
//                 "Min_Electricity": 0.0
//             }
//         },
//         "Direct_Current_Power_3": {
//             "Ping": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Monitoring_Interval": 180,
//                 "Non_Response_Count": 5
//             },
//             "Value": {
//                 "Monitoring": true,
//                 "Status_Type": "Warning",
//                 "Monitoring_Interval": 180,
//                 "Max_Electricity": 5.0,
//                 "Min_Electricity": 0.0
//             }
//         },
//         "Direct_Current_Power_4": {
//             "Ping": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Monitoring_Interval": 180,
//                 "Non_Response_Count": 5
//             },
//             "Value": {
//                 "Monitoring": true,
//                 "Status_Type": "Warning",
//                 "Monitoring_Interval": 180,
//                 "Max_Electricity": 5.0,
//                 "Min_Electricity": 0.0
//             }
//         },
//         "Direct_Current_Power_5": {
//             "Ping": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Monitoring_Interval": 180,
//                 "Non_Response_Count": 5
//             },
//             "Value": {
//                 "Monitoring": true,
//                 "Status_Type": "Warning",
//                 "Monitoring_Interval": 180,
//                 "Max_Electricity": 5.0,
//                 "Min_Electricity": 0.0
//             }
//         },
//         "Noise_Source": {
//             "Value": {
//                 "Monitoring": true,
//                 "Status_Type": "Warning",
//                 "Monitoring_Interval": 180,
//                 "Max_Temperature": 50,
//                 "Min_Temperature": 0
//             }
//         },
//         "Outdoor_Cabinet": {
//             "Value": {
//                 "Monitoring": true,
//                 "Status_Type": "Warning",
//                 "Monitoring_Interval": 180,
//                 "Max_Temperature": 50,
//                 "Min_Temperature": 0
//             }
//         },
//         "Energy_Gazer": {
//             "Ping": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Monitoring_Interval": 180,
//                 "Non_Response_Count": 5
//             },
//             "Value": {
//                 "Monitoring": true,
//                 "Status_Type": "Warning",
//                 "Monitoring_Interval": 180,
//                 "Max_Electricity": 6.0,
//                 "Min_Electricity": 0.0
//             }
//         },
//         "Lan_Switch_1": {
//             "Ping": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Monitoring_Interval": 180,
//                 "Non_Response_Count": 5
//             },
//             "SNMP": {
//                 "Monitoring": true,
//                 "Status_Type": "Info"
//             }
//         },
//         "Lan_Switch_2": {
//             "Ping": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Monitoring_Interval": 180,
//                 "Non_Response_Count": 5
//             },
//             "SNMP": {
//                 "Monitoring": true,
//                 "Status_Type": "Info"
//             }
//         },
//         "Lan_Switch_3": {
//             "Ping": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Monitoring_Interval": 180,
//                 "Non_Response_Count": 5
//             },
//             "SNMP": {
//                 "Monitoring": true,
//                 "Status_Type": "Info"
//             }
//         },
//         "Lan_Switch_4": {
//             "Ping": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Monitoring_Interval": 10,
//                 "Non_Response_Count": 5
//             },
//             "SNMP": {
//                 "Monitoring": true,
//                 "Status_Type": "Info"
//             }
//         },
//         "Measurement_Pc_Main": {
//             "Value": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Monitoring_Interval": 300
//             }
//         },
//         "Measurement_Pc_Sub": {
//             "Value": {
//                 "Monitoring": true,
//                 "Status_Type": "Error",
//                 "Monitoring_Interval": 300
//             }
//         }
//     }

// //     if (location_name === "SPE") {
// //         deviceMoniteringSettingJsonData["Spectrum_Analyzer_1"]["Ping"]["Non_Response_Count"] = 2;
// //     } else if (location_name === "SPW") {
// //         deviceMoniteringSettingJsonData["Spectrum_Analyzer_1"]["Ping"]["Non_Response_Count"] = 3;
// //     } else if (location_name === "SAYADO") {
// //         deviceMoniteringSettingJsonData["Spectrum_Analyzer_1"]["Ping"]["Non_Response_Count"] = 4;
// //     }

//     const result = await API.graphql(graphqlOperation(createDeviceMoniteringSetting, {input: {location_name: location_name, setting_json: JSON.stringify(deviceMoniteringSettingJsonData)}}));
//     console.log("deviceMoniteringSetting", result.data.createDeviceMoniteringSetting);

// }

